import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/element7.svg';

export default makeStyles((theme) => ({
  confiancesWrapper: {
    color: '#fff',
    paddingTop: 185,
    paddingBottom: theme.spacing(29),
    position: 'relative',
    '@media (max-width: 1200px)': {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    '@media (max-width: 992px)': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '@media (max-width: 768px)': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  confiancesTitle: {
    maxWidth: 1500,
    color: '#272727',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:after': {
      backgroundColor: '#D34246',
    },
  },
  confiancesDescription: {
    fontSize: '1.250rem',
  },
  confiancesList: {
    borderRadius: 84,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '@media (max-width: 1200px)': {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
    '@media (max-width: 768px)': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  confiancesListItem: {
    width: '14.28%',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '@media (max-width: 768px)': {
      minWidth: 150,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  confiancesListItemIcon: {
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.875rem',
    color: '#333333',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
    },
    '& img': {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 150,
      maxHeight: 150,
      width: 'auto',
      height: 'auto',
    },
  },
  confiancesSlickArrow: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  confiancesSlickNext: {
    right: 0,
  },
  confiancesSlickPrev: {
    left: 0,
  },
}));
