import React from 'react';
import styles from './style';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import IconArrowRightAlt from '../../../Common/Icons/IconArrowRightAlt';

import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

interface PropTypes {
  title: string;
  items: string[];
  backgroundColor: string;
  url: string;
}
const OtherOffersItem = ({ title, items, url, backgroundColor }: PropTypes) => {
  const classes = styles();
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.OtherOffersItem}
        bgcolor={backgroundColor && backgroundColor}
        style={backgroundColor ? { color: '#fff' } : {}}
      >
        <Box>
          <Typography variant="h6" className={classes.OtherOffersItemTitle}>
            {title}
          </Typography>
          <Box className={classes.OtherOffersItemListWrapper}>
            <List className={classes.OtherOffersItemList}>
              {items.map((item, index) => (
                <ListItem
                  key={index}
                  className={classes.OtherOffersItemListItem}
                >
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  <ListItemText
                    className={classnames(
                      'textParagraph',
                      classes.OtherOffersItemText,
                    )}
                    primary={item}
                  />
                </ListItem>
              ))}
            </List>

            <Box mt={2} display="flex" flexDirection="column">
              <Button
                href={url}
                className={classnames(
                  'classicButton',
                  classes.OtherOffersItemButton,
                )}
                variant="outlined"
                endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
                style={
                  backgroundColor ? { color: '#fff', borderColor: '#fff' } : {}
                }
              >
                Découvrir
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OtherOffersItem;
