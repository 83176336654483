import React from 'react';
import styles from './style';
import { Typography, Box, Button } from '@material-ui/core';
import Layout from '../../../Common/Layout';
import ActusEntries from './ActusEntries/ActusEntries';

import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import { useStaticQuery, graphql } from 'gatsby';
import { TransitionLink } from '../../../Common/TransitionLink/TransitionLink';

import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  title?: string;
}

const Actus = ({ title }: PropTypes) => {
  const classes = styles();

  const data: {
    allWpPost: {
      edges: [
        {
          node: {
            id: string;
            title: string;
            excerpt: string;
            slug: string;
            featuredImage: {
              node: {
                sourceUrl: string;
              };
            };
          };
        },
      ];
    };
  } = useStaticQuery(graphql`
    query($order: [SortOrderEnum] = DESC, $fields: [WpPostFieldsEnum] = date) {
      allWpPost(sort: { order: $order, fields: $fields }) {
        edges {
          node {
            title
            excerpt
            slug
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Box className={classes.actusContainer}>
      <Layout>
        <Box className={classes.actusContent}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.titreActualite)}
            >
              {ReactHtmlParser(title || '')}
            </Typography>
            <TransitionLink
              to="/blog"
              style={{ textDecoration: 'none' }}
              className={classes.actusButtonMore}
            >
              <Button
                color="secondary"
                variant="contained"
                endIcon={<ArrowRightAlt />}
                className="classicButton"
              >
                Voir plus
              </Button>
            </TransitionLink>
          </Box>
          <Typography
            className={classnames(
              'textParagraphe',
              classes.paragrapheActualite,
            )}
          >
            Découvrez les actus de HaiRun Technology, des astuces et pleins
            d'autres items autour de la technologie et du digital.
          </Typography>
        </Box>
        <Box className={classes.slideContainer}>
          {data.allWpPost.edges.map(({ node }, index) => {
            if (index < 3) {
              const image = node.featuredImage
                ? node.featuredImage.node.sourceUrl
                : '';
              return (
                <ActusEntries
                  key={index}
                  title={node.title}
                  image={image}
                  intro={node.excerpt}
                  url={`/blog/${node.slug}`}
                />
              );
            }
          })}
        </Box>
      </Layout>
    </Box>
  );
};

export default Actus;
