import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  textHome: {
    textAlign: "right",
    width: "90%",
    maxWidth:1200,
    margin:" 50px auto 50px auto",
    fontSize: "1.3rem", 
    color:"#808080",
    padding:"50px 0 50px 0",
    '& p':{
      fontSize:'1.6rem',
      marginRight:40,
    },
     '& h1':{
      fontSize:"3.6rem",
      position:'relative',
      fontWeight:'800',
      marginRight:40,
      padding:'50px 0',
      '&:after': {
        backgroundColor: '#D34246',
        right: "0%",
        left: 'inherit',      
        position: "absolute",
        bottom: "30px",
        width: "170px",
        height: "2px",
        display: "inline-block",
        content:"''",
    }
  },
    '& span':{
      fontWeight:'800',
      color:"#000"
    },
    '& h2':{
      marginTop:70,
      marginBottom:70,
      display:'flex',
      flexDirection:'column',
    
    }
  },

}));
