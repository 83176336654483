import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  OtherOffersItem: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(8.75),
    paddingBottom: theme.spacing(31),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '50%',
    color: '#808080',
    '@media (max-width: 1200px)': {
      paddingTop: 25,
      paddingBottom: 25,
    },
    '@media (max-width: 992px)': {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    '@media (max-width: 768px)': {
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
    },
    '@media (max-width: 576px)': {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  OtherOffersItemTitle: {
    fontSize: '2.125rem',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    '@media (max-width: 1200px)': {
      fontSize: '1.850rem',
    },
    '@media (max-width: 992px)': {
      fontSize: '1.5rem',
    },
    '@media (max-width: 768px)': {
      fontSize: '1.25rem',
      marginBottom: 0,
    },
    '@media (max-width: 576px)': {},
  },
  OtherOffersItemListWrapper: {},
  OtherOffersItemList: {},
  OtherOffersItemListItem: {
    padding: theme.spacing(1),
    alignItems: 'flex-start',
    '@media (max-width: 992px)': {
      padding: 5,
    },
  },
  OtherOffersItemListIcon: {
    backgroundColor: '#EBB32A',
    color: '#fff',
    borderRadius: '50%',
    marginRight: 10,
    marginTop: 6,
    width: 20,
    height: 20,
  },
  OtherOffersItemText: {
    '& span': {
      fontSize: '1rem',
    },
  },
  OtherOffersItemButton: {
    borderColor: '#272727',
    fontSize: '0.825rem',
    width: 'auto',
    maxWidth: 'fit-content',
    marginLeft: 10,
  },
}));
