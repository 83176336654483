import React from 'react';
import styles from './style';

import Layout from '../../../Common/Layout';
import { Typography, Box, Button } from '@material-ui/core';
import IconArrowRightAlt from '../../../Common/Icons/IconArrowRightAlt';

import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { useTheme } from '../../../Container';

interface PropTypes {
  serviceTitle: string;
  children: any;
}

const OtherOffers = ({ serviceTitle, children }: PropTypes) => {
  const classes = styles();

  const devis = useTheme();
  const handleDevisState = () => {
    devis.toggle();
  };

  return (
    <Box id="autres-services" bgcolor="#F8FCFF">
      <Layout className={classes.autresServiceContainer}>
        <Box className={classes.autresServicesWrapper}>
          <Box
            className={classes.autresServicesTitreWrapper}
            bgcolor="#E0363A"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            color="#fff"
          >
            <Typography
              variant="h2"
              className={classnames(
                'titreSection',
                classes.autresServicesTitre,
              )}
            >
              {ReactHtmlParser(serviceTitle)}
            </Typography>
            <Box mt={2} display="flex" flexDirection="column">
              Prêt à démarrer votre projet ?
              <Button
                onClick={handleDevisState}
                className={classnames(
                  'classicButton',
                  classes.autresServicebtnDemarrer,
                )}
                variant="outlined"
                endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
              >
                Obtenir un devis
              </Button>
            </Box>
          </Box>
          <Box className={classes.autresServicesItemsWrapper}>{children}</Box>
        </Box>
      </Layout>
    </Box>
  );
};

export default OtherOffers;
