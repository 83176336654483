import React from 'react';
import styles from './style';
import Layout from '../../../Common/Layout';
import { Typography, Box } from '@material-ui/core';
import classnames from 'classnames';
import gtiClock from '../../../../images/accueil/icon_time.svg';
import serviceIcon from '../../../../images/accueil/icon_forfait.svg';
import compatibleIcon from '../../../../images/accueil/icon_dispositif.svg';

import imgIllustration from '../../../../images/accueil/img_intervention.svg';

interface PropTypes {
  serviceTitle: string;
}

const ModeIntervention = ({ serviceTitle }: PropTypes) => {
  const classes = styles();

  return (
    <Box className={classes.garantie}>
      <Layout className={classes.containerGarantie}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.listItemsGarantie}
        >
          <Typography variant="h3">Nos modes d'intervention</Typography>
          <ul>
            <li className={classnames('textParagraphe')}>
              <img src={gtiClock} alt="image clock" />
              En régie à temps plein
            </li>
            <li className={classnames('textParagraphe')}>
              <img src={serviceIcon} alt="Service icons" />
              Au forfait
            </li>
            <li className={classnames('textParagraphe')}>
              <img src={compatibleIcon} alt="compatible icons" />
              Dispositif dédié <br />
              (Chef de projet, développeurs, intégrateurs…)
            </li>
          </ul>
        </Box>
        <Box className={classes.contentImg}>
          <img src={imgIllustration} alt=" image illustration" />
        </Box>
      </Layout>
    </Box>
  );
};

export default ModeIntervention;
