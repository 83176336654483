import React from 'react';

import classnames from 'classnames';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';

import { Box, Typography, ListItem, ListItemIcon } from '@material-ui/core';
import Layout from '../../../Common/Layout';
import SlickSlider from '../../../Common/SlickSlider';
import { PrevArrow, NextArrow } from '../../../Common/SlickSlider/Arrow';

import Abacus from '../../../../images/Abacus.png';
import Ancmf from '../../../../images/Ancmf.png';
import Apitipi from '../../../../images/Apitipi.png';
import BlowLtd from '../../../../images/blow-ltd.png';
import Egapt from '../../../../images/egapt.png';
import Eliotek from '../../../../images/Eliotek.png';
import Ewattch from '../../../../images/ewattch.png';
import SuperU from '../../../../images/logo-super-u.jpg';
import Selectively from '../../../../images/selectively.png';
import Shareplace from '../../../../images/Shareplace.png';
import Smartpredict from '../../../../images/Smartpredict.png';

const ConfiancesData = [
  {
    id: 1,
    title: 'Abacus',
    image: Abacus,
  },
  {
    id: 2,
    title: 'Ancmf',
    image: Ancmf,
  },
  {
    id: 3,
    title: 'Apitipi',
    image: Apitipi,
  },
  {
    id: 4,
    title: 'BlowLtd',
    image: BlowLtd,
  },
  {
    id: 5,
    title: 'Egapt',
    image: Egapt,
  },
  {
    id: 6,
    title: 'Eliotek',
    image: Eliotek,
  },
  {
    id: 7,
    title: 'Ewattch',
    image: Ewattch,
  },
  {
    id: 8,
    title: 'SuperU',
    image: SuperU,
  },
  {
    id: 9,
    title: 'Selectively',
    image: Selectively,
  },
  {
    id: 10,
    title: 'Shareplace',
    image: Shareplace,
  },
  {
    id: 11,
    title: 'Smartpredict',
    image: Smartpredict,
  },
];

interface PropTypes {
  title: string;
}
const Confiances = ({ title }: PropTypes) => {
  const classes = styles();

  const slickSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    prevArrow: (
      <PrevArrow
        classes={classnames(
          classes.confiancesSlickArrow,
          classes.confiancesSlickPrev,
        )}
      />
    ),
    nextArrow: (
      <NextArrow
        classes={classnames(
          classes.confiancesSlickArrow,
          classes.confiancesSlickNext,
        )}
      />
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box id="bloc-confiance" className={classes.confiancesWrapper}>
      <Box className={classes.background} />
      <Layout>
        <Box>
          <Typography
            variant="h2"
            className={classnames('titreSection', classes.confiancesTitle)}
          >
            {ReactHtmlParser(title)}
          </Typography>
          <SlickSlider
            settings={slickSettings}
            classesnames={classes.confiancesList}
          >
            {ConfiancesData.map(({ id, image, title }) => (
              <ListItem key={id} className={classes.confiancesListItem}>
                <ListItemIcon className={classes.confiancesListItemIcon}>
                  <img src={image} alt={title} />
                </ListItemIcon>
              </ListItem>
            ))}
          </SlickSlider>
        </Box>
      </Layout>
    </Box>
  );
};

export default Confiances;
