import React from 'react';

import styles from './style';

import {
  Card,
  CardHeader,
  CardMedia,
  Typography,
  Button,
  Box,
} from '@material-ui/core';

import IconArrowRightAlt from '../../../Common/Icons/IconArrowRightAlt';
import IconLogiciel from '../../../Common/Icons/IconLogiciel';
import IconSite from '../../../Common/Icons/IconSite';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import img3 from '../../../../images/img_dev_sur mesure.svg';
import img4 from '../../../../images/img_refonte.svg';

import ReactHtmlParser from 'react-html-parser';

import Layout from '../../../Common/Layout';
import classnames from 'classnames';

interface ICardOffer {
  cardOfferTitle: string;
}

const services = [
  {
    id: 1,
    title: "    Développement <span>de projets digitaux</span> sur mesure",
    items: [
      // 'Logiciel <span>100% personnalisé</span>',
      // 'Interface <span>ergonomique</span>',
      // 'Application <span>évolutive</span>',
      // 'Fonctionnalités <span>à la demande</span>',
      // 'Compatible <span>mobile</span>',
      " Développement application web et/ou mobile sur mesure",
      " Refonte site Internet",
      " Développement back-end et API",
    ],
    icon: <IconLogiciel width="48" height="48" viewBox="0 0 48 48" />,
    image: img3,
    url: '/developpement-sur-mesure',
  },
];

const CardOffer = ({ cardOfferTitle }: ICardOffer) => {
  const classes = styles();

  return (
    <Box id="cardOffers" 
    className={classnames(
      'styleCardOff',
      classes.cardOfferSection
    )}    >
      <Box className={classes.background} />
      <Layout className={classes.cardOffersContainer}>
        <Box display="flex" flexDirection="column">
          <Typography
            variant="h2"
            align="right"
            className={classnames('titreSection', classes.cardOffersTitle)}
          >
            {ReactHtmlParser(cardOfferTitle)}
          </Typography>
        </Box>

        <Box className={classes.cardOfferWrapper}>
          {services.map(({ id, title, items, icon, image, url }, index) => (
            <Card className={classnames("test",classes.cardRoot)} key={id}>
              <Box
                classes={{ root: classes.card }}
                component="div"
                style={index % 2 === 0 ? { order: 2 } : { order: 1 }}
              >
                <CardHeader
                  avatar={icon}
                  title={ReactHtmlParser(title)}
                  className={classnames('cardTextOff',classes.cardTitle)}
                />
                <Box className={classes.rollover}>
                  {items.map((item, index) => (

                    <Typography
                      key={index}
                      className={classnames('textParagraph', classes.cardText)}
                      >
                <ChevronRightIcon  className={classes.listeIcons}/>
                      {ReactHtmlParser(item)}
                    </Typography>
                  ))}
                  <Box mt={2} display="flex">
                    <Button
                      href={url}
                      className={classnames(
                        'classicButton',
                        classes.btnDemarrer,
                      )}
                      variant="outlined"
                      endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
                    >
                      Découvrir
                    </Button>
                  </Box>
                </Box>
              </Box>
              <CardMedia
                classes={{ root: classes.cardMedia }}
                image={image}
                title="card media"
                style={index % 2 === 0 ? { order: 1 } : { order: 2 }}
              />
            </Card>
          ))}
        </Box>
      </Layout>
    </Box>
  );
};

export default CardOffer;
