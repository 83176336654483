import React from 'react';
import styles from './style';
import Layout from '../../../Common/Layout';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import classnames from 'classnames';

import imgIllustration from '../../../../images/img2.svg';

import ReactHtmlParser from 'react-html-parser';

interface PropTypes {
  serviceTitle: string;
}

const paragraphes = [

  "L'ADN de<span> HaiRun Technology </span>regroupe <span>valeurs humaines:</span> culture de l'excellence et expertise au service de ses clients. Notre culture d'entreprise repose sur<span> 4 piliers de valeurs partagés</span> par l'ensemble de nos collaborateurs.",
];

const valeurs = ["ENGAGEMENT<br><p>Nos collaborateurs sont des ingénieurs engagés qui assurent la bonne réalisation des travaux demandés quelles que soient les contraintes et les problèmes rencontrés.</p> ", "QUALITÉ <br><p>Tous nos livrables sont conçus et livrés selon des normes de qualité élevées ! Des processus sont mis en place pour garantir aux clients la meilleure expérience possible. </p>", "AGILITÉ <br><p>Quelles que soient les caractéristiques des projets, nos collaborateurs ont été formés pour s'adapter en continu aux besoins du client.</p>","INTÉGRITÉ <br><p>L'intégrité est inséparable de la culture d'entreprise de HaiRun Technology. Nos collaborateurs sont intègres et loyaux, ce qui fait la vraie force de l'entreprise.</p>"];

const Offers = ({ serviceTitle }: PropTypes) => {
  const classes = styles();

  return (
    <Box bgcolor="#FFF" className={classes.offersSection}>
      <Box className={classes.background} />
      <Box className={classes.offers} id="nos-services">
        <Layout className={classes.offersContainer}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.offersText}
          >
            <Typography
              variant="h2"
              className={classnames('titreSection', classes.offersTitle)}
            >
              {ReactHtmlParser(serviceTitle)}
            </Typography>

            {paragraphes.map((item, index) => (
              <Typography
                key={index}
                className={classnames(
                  'textParagraphe',
                  classes.offersParagraphe,
                  index % 2 === 0
                    ? classes.offersParagrapheOdd
                    : classes.offersParagrapheEven,
                )}
              >
                {ReactHtmlParser(item)}
              </Typography>
            ))}

            <List
              className={classes.offersListValue}
              aria-label="secondary mailbox folders"
            >
              {valeurs.map((item, index) => (
                <ListItem key={index} disableGutters>
                  <ListItemText
                    primary={ReactHtmlParser(item)}
                    className={classnames('textValue', classes.offersCitation)}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box className={classes.offersIllustrationWrapper}>
            <img src={imgIllustration} alt="hero" />
          </Box>
        </Layout>
      </Box>
    </Box>
  );
};

export default Offers;
