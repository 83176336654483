import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@material-ui/core';

import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import styles from './style';
import Layout from '../../../Common/Layout/Layout';
import SocialNetwork from '../../../Common/SocialNetwork';
import { AnchorLink as Link } from 'gatsby-plugin-anchor-links';

import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

const heroTitle = 'High level <br /><span>Software</span> development';
const heroDescription =
  'HaiRun Technology est une <span>entreprise de service du numérique </span>(ESN), <br/>leader en <span>développement de logiciels</span> à Madagascar et en France. ';

const Hero = () => {
  const classes = styles();
  // const isBodyShift = useTheme().isBodyShift;

  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <Box className={classnames(active ? 'active' : '', classes.hero)} id="hero">
      <Box className={classnames(active ? 'active' : '', classes.background)} />
      {/* <Box className={classes.scroll}>
        <Link to="/#nos-services">
          <ArrowLeft />
          <Typography variant="body2" component="span">
            scroll
          </Typography>
        </Link>
      </Box> */}
      <Layout className={classes.heroContainer}>
        <Box className={classes.heroContent}>
          <Box
            className={classnames(active ? 'active' : '', classes.textIntro)}
          >
            <Box className={classes.text}>
              <Typography
                variant="h1"
                className={classnames('titreSection', classes.title)}
              >
                {ReactHtmlParser(heroTitle)}
              </Typography>

              <Typography className={classes.paragraph}>
                {ReactHtmlParser(heroDescription)}
              </Typography>

              <Box display="flex">
                <Link
                  to="/#cardOffers"
                  title="Commencer"
                  className={classes.heroBtnServiceWrapper}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<ArrowRight color="inherit" />}
                    className={classnames('classicButton')}
                  >
                    Commencer
                  </Button>
                </Link>
              </Box>
            </Box>
            <Box className={classes.btnWrapper}>
              <Box className={classes.buttonPlus}>
                <Box display="flex" flexDirection="column">
                  <Typography component="span" className={classes.followUs}>
                    Suivez-nous sur :
                  </Typography>
                  <SocialNetwork htmlColor="#272727" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </Box>
  );
};

export default Hero;
