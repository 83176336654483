import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/img1.svg';
import Overlay from '../../../../images/home-overlay.svg';
// import DotImg from '../../../../images/dot.png';

export default makeStyles((theme) => ({
  hero: {
    position: 'relative',
    '@media (min-width:1200px)': {
      height: 'calc(100vh - 86px)',
      maxHeight: 947,
    },
    '&::before': {
      width: '100%',
      height: '100%',
      display: 'inline-block',
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      zIndex: 2,
      background: `url(${Overlay})`,
      backgroundPosition: 'right center',
      // opacity: 0,
      // transitionDuration: '0.6s',
      // transitionTimingFunction: 'ease-in-out',
    },
    // '&.active::before': {
    //   opacity: 1,
    // },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '& button': {
        fontSize: '0.875rem',
        textDecoration: 'none',
        borderRadius: 4,
        borderColor: '#272727',
        color: '#272727',
        boxShadow: 'none',
        transition: '50ms ease-in',
        '&:hover': {
          paddingLeft: 20,
          paddingRight: 20,
          transition: '50ms ease-in',
        },
        '& svg': {
          transition: '50ms ease-in',
        },
        '&:hover svg': {
          transition: '50ms ease-in',
          transform: 'translateX(5px)',
        },
      },
    },
  },
  heroContainer: {
    maxWidth: 1460,
  },
  heroContent: {
    position: 'relative',
    zIndex: 3,
    height: '100%',
    '&::after': {
      position: 'absolute',
      bottom: 20,
      left: -235,
      display: 'inline-block',
      content: "''",
      width: 160,
      height: 140,
      /* backgroundImage: `url(${DotImg})`, */
      backgroundSize: 'cover',
    },
    '&::before': {
      position: 'absolute',
      bottom: 105,
      right: -215,
      display: 'inline-block',
      content: "''",
      width: 291,
      height: 257,
      /* backgroundImage: `url(${DotImg})`,*/
      backgroundSize: 'cover',
      '@media (max-width:576px)': {
        opacity: '0.25',
      },
    },
  },
  textIntro: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
    // transitionDuration: '1.2s',
    // transitionTimingFunction: 'ease-in-out',
    // transform: 'translateX(-100%)',
    // '&.active': {
    //   transform: 'none',
    // },
    '& img': {
      marginTop: theme.spacing(2),
    },
    '@media (max-width:1400px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    '@media (max-width:800px)': {
      '& img': {
        width: 200,
      },
    },
    '@media (max-width:425px)': {
      width: '100%',
      '&>div:not(:last-of-type)': {
        width: '100%',
        marginBottom: theme.spacing(4),
      },
      '& button': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  text: {
    width: '100%',
    height: '100%',
    // maxWidth: 710,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width: 1200px)': {
      marginBottom: theme.spacing(10),
    },
  },
  heroBtnServiceWrapper: {
    marginLeft: theme.spacing(4),
    '@media (max-width: 768px)': {
      marginLeft: 0,
    },
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '2rem',
    '@media (max-width: 576px)': {
      width: '100%',
      paddingBottom: 0,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: 'no-repeat',
    // backgroundPositionX: '200%',
    backgroundPositionX: '95%',
    backgroundPositionY: '84%',
    // transitionDuration: '1s',
    // transitionTimingFunction: 'ease-in-out',
    // '&.active': {
    // },
    '@media (max-width: 1650px)': {
      maxWidth: '50%',
      left: 'inherit',
      right: '5%',
      backgroundPosition: '0 76%',
      backgroundSize: 'contain',
    },
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  logo: {
    width: 300,
  },
  title: {
    marginRight: theme.spacing(4),
    color: '#272727',
    '@media (max-width: 1200px)': {},
    '@media (max-width: 992px)': {},
    '@media (max-width: 768px)': {},
    '@media (max-width: 576px)': {},
    '& span': {},
    '&:after': {
      backgroundColor: '#E0363A',
    },
  },
  paragraph: {
    marginBottom: theme.spacing(4),
    position: 'relative',
    // maxWidth: 500,
    fontSize: '1.5rem',
    fontWeight: 300,
    paddingLeft: theme.spacing(4),
    color: '#808080',
    '@media (max-width: 992px)': {
      fontSize: '1.250rem',
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      paddingLeft: 0,
    },
    '& span': {
      fontWeight: 700,
      color: '#E0363A',
    },
  },
  buttonPlus: {
    '&>button': {
      marginRight: theme.spacing(3),
      fontWeight: 600,
    },
    '@media (max-width:576px)': {
      textAlign: 'center',
      '&>button': {
        marginRight: 0,
      },
      '&>div': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& span': {
          '@media (max-width:576px)': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          },
        },
      },
    },
  },
  cardOffer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  followUs: {
    marginBottom: theme.spacing(2),
    color: '#272727',
    fontSize: '.75rem',
  },
  scroll: {
    zIndex: 4,
    position: 'absolute',
    right: 0,
    bottom: theme.spacing(10),
    transform: 'rotate(-90deg)',
    textTransform: 'uppercase',
    color: '#333333',
    // color: theme.palette.secondary.main,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    borderRadius: 25,
    padding: theme.spacing(0.75, 2),
    boxShadow: '0 0 5px 2px rgba(0,0,0,.16)',
    '& a': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: '1.5rem',
    },
    '& span': {
      fontWeight: 600,
    },
    '@media (max-width:1024px)': {
      right: 20,
      padding: 10,
      '& span': {
        display: 'none',
      },
      '& svg': {
        marginRight: 0,
      },
    },
    '@media (max-width:800px)': {
      display: 'none',
    },
  },
}));
