import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/element3.svg';

export default makeStyles((theme) => ({
  cardOfferSection: {
    position: 'relative',
    paddingBottom: 20,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundPosition: '0px 0px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    '@media (max-width: 1200px)': {
      backgroundPosition: '100% 100%',
    },
  },
  cardOffersContainer: {
    maxWidth: 1745,
    paddingTop: theme.spacing(2),
  },
  cardOffersTitle: {
    '&:after': {
      backgroundColor: '#D34246',
      right: 0,
      left: 'inherit',
    },
    '@media (max-width: 768px)': {
      textAlign: 'left !important',
      '&:after': {
        backgroundColor: '#D34246',
        right: 'inherit',
        left: 0,
      },
    },
  },
  cardOfferWrapper: {
  },
  cardRoot: {
    display: 'flex',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(10),
    // paddingBottom: theme.spacing(15),
    '@media (max-width: 1200px)': {
      paddingTop: 20,
    },
    '@media (max-width: 768px)': {
      paddingTop: 0,
      flexWrap: 'wrap',
    },
  },
  cardOffer: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {},
  },
  styleCardOff :{
    textAlign:'left',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    width: '50%',
    // padding: theme.spacing(5),
    // paddingTop: theme.spacing(15),
    // paddingBottom: theme.spacing(15),
    // paddingLeft: '0%',
    '@media (max-width: 1200px)': {
      paddingTop: 10,
      paddingLeft: 0,
      paddingBottom: 10,
    },
    '@media (max-width: 992px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '@media (max-width: 768px)': {
      width: '100%',
      order: '2 !important',
    },
    '&:hover>div:last-of-type': {
      opacity: 1,
    },
  },
  cardMedia: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',
    '@media (max-width: 768px)': {
      height: 365,
      order: '1 !important',
      flexBasis: '100%',
    },
    '@media (max-width: 576px)': {
      height: 250,
    },
  },
  cardContent: {
    padding: 0,
  },
  cardTitle: {
    paddingLeft: 0,
    '@media (max-width: 992px)': {
      paddingRight: 0,
      width: '100%',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      margin: 0,
      '& span': {},
      '& > .MuiCardHeader-content': {
        maxWidth: 'fit-content',
      },
    },
    '@media (max-width: 576px)': {},
    '& > .MuiCardHeader-avatar': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 77,
      height: 77,
      border: '1px solid #27272729',
      borderRadius: '50%',
      '@media (min-width: 769px) and (max-width: 991px)': {
        width: 44,
        height: 44,
      },
      '@media (max-width: 576px)': {
        width: 44,
        height: 44,
      },
      '& svg': {
        fontSize: '3rem',
        '@media (min-width: 769px) and (max-width: 991px)': {
          fontSize: '2rem',
        },
        '@media (max-width: 576px)': {
          fontSize: '2rem',
        },
      },
    },
    '& span': {
      lineHeight: 1.2,
      fontWeight: 300,
      textTransform: 'initial',
      fontFamily: 'ubuntu',
      fontSize: '2.125rem',
      '@media (min-width: 1200px) and (max-width: 1600px)': {
        fontSize: '2rem',
      },
      '@media (min-width: 992px) and (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width: 769px) and (max-width: 991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      '& span': {
        fontWeight: 600,
      },
      '& br': {
        '@media (max-width: 768px)': {
          display: 'none',
        },
      },
    },
  },
  listeIcons:{
    backgroundColor: '#EBB32A',
    color: '#fff',
    borderRadius: '50%',
    marginRight: 10,
    // marginTop: 6,
    width: 20,
    height: 20,
    '@media (max-width: 1200px)': {
      backgroundColor: '#D34246',
    },
  },
  cardText: {
    color: '#808080',
    textAlign: 'left',
    display:'flex',
    alignItems:'center',
    marginBottom: theme.spacing(2),
    '@media (max-width: 992px)': {
      fontSize: '1.125rem',
      marginBottom: 5,
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      textAlign: 'left',
    },
    '@media (max-width: 576px)': {},
    '& span': {
      color: '#E0363A',
      fontWeight: 600,
    },
  },
  divider: {
    width: 30,
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#fff',
    backgroundColor: 'currentColor',
  },
  rollover: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& > div': {
      textAlign: 'center',
    },
  },
  btnDemarrer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    borderRadius: 4,
    borderColor: '#272727',
    color: '#272727',
    boxShadow: 'none',
    transition: '50ms ease-in',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media (max-width: 360px)': {
      fontSize: '0.725rem',
    },
    '&:hover': {
      paddingLeft: 20,
      paddingRight: 20,
      transition: '50ms ease-in',
    },
    '& svg': {
      transition: '50ms ease-in',
    },
    '&:hover svg': {
      transition: '50ms ease-in',
      transform: 'translateX(5px)',
    },
  },
}));
