import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    display: 'flex',
    marginTop: theme.spacing(2),
    minHeight: 305,
    '@media (min-width: 992px)': {
      gridColumn: '1 / span 2',
    },
    '@media (max-width: 992px)': {
      minHeight: 250,
    },
    '&>*': {
      flex: '0 0 50%',
      maxWidth: '50%',
      '@media(max-width:576px)': {
        // flex: '0 0 100%',
        // maxWidth: '100%',
      },
    },
    '@media(max-width:576px)': {
      minHeight: 250,
      height: 'auto',
      margin: theme.spacing(1),
      flexDirection: 'column-reverse',
    },
    '&:last-child': {
      height: 'inherit',
      '@media (min-width: 992px)': {
        gridColumn: '3',
        gridRow: '1 / span 2',
        flexDirection: 'column-reverse',
      },
      '@media (max-width: 992px)': {
        display: 'none',
      },
      '&>*': {
        maxWidth: '100%',
      },
    },
  },
  imgActus: (props: any) => ({
    background: `url(${props.image}) left center /cover no-repeat`,
    '& img': {
      width: '100%',
      display: 'none',
      '@media(max-width:576px)': {
        display: 'none',
        // background: 'none',
      },
    },
    '@media(max-width:576px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: 'unset',
      height: 180,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  }),
  actusContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 3, 4),
    color: '#272727',
    alignItems: 'flex-start!important',
    '& h2': {
      marginBottom: theme.spacing(2),
      fontWeight: 400,
      fontSize: '2.125rem',
      fontFamily: 'ubuntu',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      '@media (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 576px)': {
        fontSize: '1.5rem',
      },
    },
    '& p': {
      fontSize: '1rem',
      color: '#808080',
      margin: 0,
      '@media (max-width: 576px)': {
        fontSize: '0.825rem',
      },
    },
    '& a': {
      width: 'auto',
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      marginTop: 'auto',
      '& button': {
        borderRadius: 4,
        borderColor: '#D34246',
        color: '#D34246',
        transition: '50ms ease-in',
        '@media(max-width:576px)': {
          marginTop: theme.spacing(4),
        },
        '&:hover': {
          borderColor: '#D34246',
          paddingLeft: 20,
          paddingRight: 20,
          transition: '50ms ease-in',
        },
        '& svg': {
          transition: '50ms ease-in',
        },
        '&:hover svg': {
          transition: '50ms ease-in',
          transform: 'translateX(5px)',
        },
      },
    },
    '@media(max-width:576px)': {
      minWidth: '100%',
    },
  },
  textElipsis: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    '@media(max-width:576px)': {
      '-webkit-line-clamp': 2,
    },
  },
}));
