import React, { useEffect, useState } from 'react';
import Hero from './Hero';
import Offers from './Offers';
import Actus from './Actus';
import OtherOffers from './OtherOffers';
import OtherOffersItem from './OtherOffersItem';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '../../Container';
import Technologies from '../../Common/Technologies';
import Confiances from './Confiances';
import Temoignages from './Temoignages';
import styles from './styles';
//import PartnerAws from './PartnerAws';
import ModeIntervention from './ModeIntervention';
import CardOffer from './CardOffer';
import ContactForm from '../../Common/ContactForm';
import ScrollTo from '../../Common/ScrollTo';

import IconAngular from '../../Common/Icons/IconAngular';
import IconReact from '../../Common/Icons/IconReact';
import IconDrupal from '../../Common/Icons/IconDrupal';
import IconNode from '../../Common/Icons/IconNode';
import IconSymfony from '../../Common/Icons/IconSymfony';
import classNames from 'classnames';
import classnames from 'classnames';

const OtherOffersItemData = [
  {
    id: 1,
    title: 'Infogérance ',
    items: [
"Supervision des infrastructures serveur, stockage et réseau hébergé.",
"Administration et maintenance préventive",
"Sécurisation réseau et systèmes",
      // 'Services de supervision',
      // "Résolution d'incidents",
      //'Administration',
      //'Gestion des demandes',
      //'Service Management',
      //'Professional Services',
      //'Maintenance prédictive',
      // 'Infogérance 365/365 24/24 7/7',
      // "Résolution d'incidents",
      // "Déploiement rapide et sécurisé",
      // "Intégration continue",
      // "Conception et Migration d'infrastructures",
      // " Automatisation des tâches",
      // "Infrastructure as Code",
    ],
    backgroundColor: '#0E0C38',
    url: '/infogerance',
  },
  {
  id: 2,
  title: 'DevOps',
  items: [
"Système d'intégration continue et de déploiement continu",
"Architecture microservice",
"Orchestration et mise à l'échelle des services applicatifs",
"Solution Serverless ",
"Infrastructure as code",
"Sécurisation des services",
    // 'Services de supervision',
    // "Résolution d'incidents",
    //'Administration',
    //'Gestion des demandes',
    //'Service Management',
    //'Professional Services',
    //'Maintenance prédictive',
    // 'Infogérance 365/365 24/24 7/7',
    // "Résolution d'incidents",
    // "Déploiement rapide et sécurisé",
    // "Intégration continue",
    // "Conception et Migration d'infrastructures",
    // " Automatisation des tâches",
    // "Infrastructure as Code",
  ],
  backgroundColor: '#0E0C38',
  url: '/infogerance',
},
  {
    id: 3,
    title: 'Data science',
    items: [
      // 'Consultants expérimentés',
      // 'Expertises multi-domaines',
      // 'Technologies et outils analytiques avancés',
      // 'Prestations en régie',
      "Recueil et analyse des données",
      "Création des modèles de machine learning",
      "Mise en place de pipeline de données",
      "Analyse prédictive, système de recommandation, computer vision, IoT ...",
      "Visualisation de données afin de simplifier la compréhension des informations",
      "Création de tableaux de bord: Business Intelligence (BI)",
    ],
    backgroundColor: '',
    url: '/datascientist',
  },
];

const TechnologiesItem = [
  {
    id: 1,
    icon: (
      <IconReact width="151.724" height="50.961" viewBox="0 0 151.724 50.961" />
    ),
    title: 'IconReact',
  },
  {
    id: 2,
    icon: <IconNode width="83.07" height="50.876" viewBox="0 0 83.07 50.876" />,
    title: 'IconNode',
  },
  {
    id: 3,
    icon: (
      <IconAngular
        width="187.942"
        height="50.876"
        viewBox="0 0 187.942 50.876"
      />
    ),
    title: 'IconAngular',
  },
  {
    id: 4,
    icon: (
      <IconDrupal width="212.771" height="50.96" viewBox="0 0 212.771 50.96" />
    ),
    title: 'IconDrupal',
  },
  {
    id: 5,
    icon: (
      <IconSymfony width="204.35" height="50.961" viewBox="0 0 204.35 50.961" />
    ),
    title: 'IconSymfony',
  },
];

const Home = () => {
  const devis = useTheme();
  const handleClose = () => {
    devis.toggle();
  };
  const classes = styles();
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box>
      {showScrollTo ? <ScrollTo element="/#hero" /> : null}
      <Hero />
      <Box 
      className={classnames('titreSectionHome', classes.textHome)}
      >
        <Typography component="h1">Nos services et<span> domaines d'expertise</span></Typography>
        <Typography component='p'>
        Nous sommes spécialisés dans le <span>développement de solutions numériques sur mesure, la maintenance d'applications web, mobiles, logiciels spécifiques, plateformes en ligne.</span> 
        Nous proposons également des services tels que la<span> Data science, <br/>la Business Intelligence, l'infogérance et DevOps.<br/></span>
        Notre champ d’intervention couvre tous les besoins des entreprises en matière de services numériques et transformation digitale.
        </Typography>

      <CardOffer cardOfferTitle="Des solutions sur mesure <br/>adaptées à vos besoins"/>
      </Box>
         
      <OtherOffers serviceTitle="<span>Autres domaines d’expertise</span>">
        {OtherOffersItemData.map(
          ({ id, title, items, backgroundColor, url }) => (
            <OtherOffersItem
              key={id}
              title={title}
              items={items}
              url={url}
              backgroundColor={backgroundColor}
            />
          ),
        )}
      </OtherOffers>
      <ModeIntervention serviceTitle="" />
      <Technologies
        bgcolor="#333333"
        title="Quelques unes<br/> <span>des</span> technologies <span>utilisées</span>"
        description="Les Langages, Frameworks ou Solutions que nous utilisons au quotidien"
        items={TechnologiesItem}
      />
      <Confiances title="Ils nous<br/> <span>font</span> confiance" />
      <Offers serviceTitle="<span>Pourquoi</span><br/> nous <span>choisir ?</span>" />
      <Temoignages title="Nos clients <span>aiment ce que</span> <br/>nous faisons" />
      {/*<PartnerAws serviceTitle="<span>Hairun Technology</span><br/> Partenaire <span>AWS ?</span>" />*/}
      <Actus title="<span>Le MAG</span><br/> HaiRun Technology" />
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
  );
};

export default Home;
