import React from 'react';
import Home from '../components/PagesComponent/Home';
import Container from '../components/Container';
import SEO from '../components/seo';

const Index = () => {
  return (
    <Container>
      <SEO
        lang="fr-FR"
        title="HaiRun Technology - Expert en développement informatique"
        description="Une entreprise de service du numérique ( ESN) basée à Madagascar et en France. Spécialisée dans le développement d'applications web, mobiles."
      />
      <Home />
    </Container>
  );
};

export default Index;
