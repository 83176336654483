import React from 'react';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import { Paper, Box, Typography, Button } from '@material-ui/core';

import styles from './style';
import { TransitionLink as Link } from '../../../../Common/TransitionLink/TransitionLink';
interface IActusEntries {
  image: string;
  key: number;
  title: string;
  intro: string;
  url?: string;
}

const ActusEntries = ({ image, key, title, intro, url }: IActusEntries) => {
  const classes = styles({ image });

  return (
    <Paper elevation={3} className={classes.paper} key={key}>
      <Box className={classes.actusContent}>
        <Typography variant="h2" className={classes.textElipsis}>
          {title.replace(/&nbsp;/g, ' ')}
        </Typography>
        <Box
          dangerouslySetInnerHTML={{ __html: intro }}
          className={classes.textElipsis}
        />
        {url && (
          <Link
            to={url}
            variant="outlined"
            settings={{
              cover: undefined,
              paintDrip: true,
              hex: '#FFF',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ArrowRight color="inherit" fontSize="small" />}
              className="classicButton"
            >
              Lire l'article
            </Button>
          </Link>
        )}
      </Box>
      <Box className={classes.imgActus}>
        <img src={image} alt="images Actus" />
      </Box>
    </Paper>
  );
};

export default ActusEntries;
