import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/element.svg';
import OffersBg from '../../../../images/element2.svg';
import dot from '../../../../images/dot.svg';

export default makeStyles((theme) => ({
  offersSection: {
    position: 'relative',
    backgroundColor: 'transparent',
    '& + div': {
      paddingTop: 130,
      '@media (max-width: 1200px)': {
        paddingTop: 70,
        paddingBottom: 70,
      },
      '@media (max-width: 768px)': {
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
  },
  offersContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  offers: {
    color: '#fff',
    position: 'relative',
    zIndex: 1,
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    backgroundImage: `url(${OffersBg})`,
    backgroundColor: '#333',
    backgroundPosition: '0 100%',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 1200px)': {
      paddingTop: theme.spacing(6.75),
      paddingBottom: theme.spacing(6.75),
    },
    '@media (max-width: 992px)': {
      paddingTop: 20,
      paddingBottom: 20,
    },
    '@media (max-width: 768px)': {
      backgroundPosition: '100% 0',
    },
  },
  background: {
    width: '100%',
    height: 135,
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  arrow: {
    position: 'absolute',
    top: 'calc(50% - 16px)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFF',
    zIndex: 10,
    borderRadius: '50%',
    boxShadow: '0 0 5px rgba(0,0,0,.16)',
    '&:first-of-type': {
      left: 10,
    },
    '&:last-of-type': {
      right: 10,
    },
  },
  offersText: {
    maxWidth: 1004,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  offersTitle: {
    '&:after': {
      backgroundColor: '#F1CA61',
    },
  },
  offersListValue: {
    backgroundColor: 'rgb(224 54 58 / 0.5)',
    paddingLeft: theme.spacing(18),
    paddingTop: theme.spacing(4.3),
    paddingBottom: theme.spacing(4.3),
    '@media (max-width: 768px)': {
      paddingLeft: 10,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '&:before': {
      display: 'inline-block',
      content: '""',
      // backgroundColor: 'rgb(224 54 58 / 0.5)',
      width: '300%',
      height: '100%',
      position: 'absolute',
      top: 0,
      right: '-300%',
    },
    '& > li': {
      padding: '0 20px 0 40px',
      '&:last-child > div': {
        marginBottom: 0,
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  offersCitation: {
    position: 'relative',
    marginBottom: 32,
    fontSize: '1rem',
    '@media (max-width: 768px)': {
      marginBottom: 15,
    },
    '&:before, &:after': {
      position: 'absolute',
      content: "''",
    },
    '&:before': {
      left: -39,
      top: 4,
      backgroundImage: `url(${dot})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: 17,
      height: 17,
    },
    '&:after': {
      left: -31,
      bottom: 'calc(-100% - 5px)',
      width: 1,
      height: '100%',
      // backgroundColor: '#fff',
      '@media (max-width: 768px)': {
        height: '50%',
        bottom: 'calc(-58% - 5px)',
      },
    },
    '& span': {
      color: '#fff',
    },
  },
  offersParagraphe: {
    fontSize: '1.25rem',
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    '@media (max-width: 768px)': {
      margin: '1.5rem 0',
      marginLeft: 0,
      marginRight: 0,
    },
    '& span': {
      color: '#F1CA61',
    },
  },
  offersParagrapheOdd: {
    // maxWidth: 570,
    '@media (max-width: 992px)': {
      maxWidth: 'initial',
      marginTop: 'revert',
    },
  },
  offersParagrapheEven: {
    marginTop: theme.spacing(7),
    marginLeft: theme.spacing(18),
    maxWidth: 700,
    '@media (max-width: 992px)': {
      maxWidth: 'initial',
    },
    '@media (max-width: 768px)': {
      marginTop: 'revert',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  offersIllustrationWrapper: {
    marginBottom: -130,
    '@media (max-width: 1200px)': {
      maxWidth: '30%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
}));
