import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/element.svg';
import OffersBg from '../../../../images/element2.svg';
import dot from '../../../../images/dot.svg';

export default makeStyles((theme) => ({
  garantie: {
    color: '#808080',
    fontSize: '1rem',
    '& ul': {
      paddingLeft: 0,
      listStyleType: 'none',
    },
    '& li': {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'flex-start',
      fontSize: '0.9375rem',
      '@media (max-width: 992px)': {
        padding: 5,
      },
    },
    '& svg': {
      marginTop: 0,
    },
  },
  containerGarantie: {
    display: 'flex',
    maxWidth: 1600,
    alignItems: 'center',
    paddingTop: 80,
    paddingBottom: 0,
    '@media (max-width: 992px)': {
      flexDirection: 'column',
    },
    '@media (max-width: 768px)': {
      paddingTop: 24,
    },
  },
  listItemsGarantie: {
    flex: 1,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 80,
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 60,
      '@media (max-width: 992px)': {
        marginTop: 12,
      },
      '@media (max-width: 442px)': {
        flexDirection: 'column',
      },
    },
    '& li': {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      '@media (max-width: 1380px)': {
        minWidth: 230,
      },
      '@media (max-width: 992px)': {
        padding: 5,
        minWidth: 150,
      },
      '@media (max-width: 600px)': {
        minWidth: 140,
      },
    },
    '& h3': {
      fontSize: '2.125rem !important',
      fontWeight: 700,
      color: '#272727',
      fontFamily: 'Josefin Sans',
      marginBottom: 0,
      '@media (max-width: 992px)': {
        marginBottom: 42,
      },
    },
    '@media (max-width: 1200px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '@media (max-width: 992px)': {
      width: '100%',
      marginTop: 32,
    },
  },
  contentImg: {
    display: 'flex',
    width: '35%',
    '& img': {
      width: '100%',
      '@media (max-width: 1380px)': {
        objectFit: 'cover',
      },
      '@media (max-width: 1170px)': {
        minWidth: 342,
      },
      '@media (max-width: 1100px)': {
        minWidth: 359,
      },
    },
    '@media (max-width: 1380px)': {
      width: '40%',
      height: 355,
    },
    '@media (max-width: 1200px)': {
      height: 321,
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
}));
