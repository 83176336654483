import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  actusContainer: {
    paddingTop: 100,
    paddingBottom: 100,
    color: '#808080',
    // maxWidth: 1500,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 1200px)': {
      paddingTop: 50,
      paddingBottom: 50,
    },
    '@media (max-width: 992px)': {
      paddingTop: 25,
      paddingBottom: 25,
    },
    '@media (max-width: 768px)': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& > div': {
      maxWidth: 1720,
    },
  },
  actusContent: {
    '& h2': {
      marginBottom: theme.spacing(2),
    },
    '& h6': {
      width: '80%',
      marginBottom: theme.spacing(2),
    },
    '@media (max-width: 992px)': {
      maxWidth: 'initial',
      '& h6': {
        width: '100%',
      },
    },
    '& > div': {
      '@media (max-width: 576px)': {
        alignItems: 'baseline',
      },
    },
  },
  slideContainer: {
    marginTop: theme.spacing(2),
    display: 'grid',
    '@media (min-width: 1200px)': {
      gridGap: '2%',
      gridTemplateColumns: '32% 32% 32%',
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
      gridGap: '2%',
      gridTemplateColumns: '30% 30% 36%',
    },
    '@media (max-width: 767px)': {},
    '@media (max-width: 576px)': {},
    '& .slick-slider': {
      display: 'flex',
    },
  },
  slickArrow: {
    position: 'absolute',
    height: '100%',
    top: 0,
    right: -25,

    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#E6E6E6',
    zIndex: 10,

    '&:last-of-type': {
      right: -75,
    },

    '& button': {
      marginLeft: 16,
      color: 'currentColor',
      backgroundColor: theme.palette.background.paper,
    },
    '@media (max-width: 576px)': {
      top: 'auto',
      right: 'auto',
      height: 'auto',
      bottom: -30,
      backgroundColor: 'transparent',
      '& button': {
        marginLeft: 0,
      },
      '&:first-of-type': {
        left: 'calc(45% - 16px)',
      },
      '&:last-of-type': {
        right: 'calc(45% - 16px)',
      },
    },
  },
  actusButtonMore: {
    display: 'inline-block',
    marginTop: 32,
    '@media (max-width: 576px)': {
      margin: '64px auto 0',
      textAlign: 'center',
    },
    '& button': {
      backgroundColor: '#D34246',
      boxShadow: 'none',
      transition: '50ms linear',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#D34246',
        opacity: 0.75,
        paddingLeft: 33,
        paddingRight: 33,
        marginRight: 0,
        marginLeft: 10,
        transition: '50ms linear',
      },
      '& svg': {
        transition: '50ms linear',
      },
      '&:hover svg': {
        transition: '50ms linear',
        transform: 'translateX(5px)',
      },
    },
  },
  titreActualite: {
    color: '#272727',
    '&:after': {
      backgroundColor: '#D34246',
    },
    '@media (max-width: 576px)': {
      maxWidth: 'calc(100% - 100px)',
    },
  },
  paragrapheActualite: {
    fontSize: '1.250rem',
  },
}));
