import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/fond-temoin.svg';

export default makeStyles((theme) => ({
  temoignageWrapper: {
    color: '#fff',
    paddingTop: 185,
    paddingBottom: theme.spacing(18),
    position: 'relative',

    '@media (max-width: 1200px)': {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    '@media (max-width: 992px)': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '@media (max-width: 768px)': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '& button': {
        fontSize: '0.875rem',
        textDecoration: 'none',
        borderRadius: 4,
        borderColor: '#D34246',
        color: '#fff',
        boxShadow: 'none',
        backgroundColor: '#D34246',
        transition: '50ms ease-in',
        '&:hover': {
          paddingLeft: 20,
          paddingRight: 20,
          transition: '50ms ease-in',
          backgroundColor: '#D34246',
          borderColor: '#D34246',
        },
        '& svg': {
          transition: '50ms ease-in',
        },
        '&:hover svg': {
          transition: '50ms ease-in',
          transform: 'translateX(5px)',
        },
      },
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  title: {
    color: '#272727',
    textAlign: 'center',
    marginTop: 64,
    '&:after': {
      backgroundColor: '#D34246',
      right: 0,
      margin: '0 auto',
    },
  },
  subTitle: {
    fontSize: '1.250rem',
    color: '#808080',
    textAlign: 'center',
    maxWidth: 768,
    margin: '0 auto',
  },

  temoignagesList: {
    borderRadius: 84,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: 1500,
    '@media (max-width: 1200px)': {
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(4),
    },
    '@media (max-width: 768px)': {
    },
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .slick-dots': {
      '& li': {
        margin: '0 3px',
        '& button:before': {
          fontSize: 12,
          '@media (max-width: 1024px)': {
            fontSize: 16,
          }
        },
      },
    }
  },
  listItem: {
    maxWidth: '1300px',
    width: '100%',
    margin: '0 auto',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: 'flex!important',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      minWidth: 150,
      paddingTop: 40,
      paddingBottom: theme.spacing(2),
    },
    '@media (max-width: 448px)': {
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4),
    },
    '& > blockquote': {
      position: 'relative',
      color: '#808080',
      fontSize: '1rem',
      margin: '0 0 48px',
    },
  },
  listItemIcon: {
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.875rem',
    color: '#333333',
    '& svg': {
      width: 'inherit',
      height: 'inherit',
    },
    '& img': {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 150,
      maxHeight: 150,
      width: 'auto',
      height: 'auto',
    },
  },
  slickArrow: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: 30,
    height: 30,
    zIndex: 1,
  },
  slickNext: {
    right: 0,
  },
  slickPrev: {
    left: 0,
  },
  bqstart: {
    position: 'absolute',
    fontSize: '700%',
    color: '#E0363A ',
    height: 70,
    left: -50,
    top: -70,
    '@media (max-width: 768px)': {
      fontSize: '500%',
      left: -38,
      top: -48,
    },
  },

  bqend: {
    position: 'absolute',
    fontSize: '700%',
    color: '#E0363A ',
    height: 70,
    right: -50,
    bottom: -44,
    '@media (max-width: 1024px)': {
      right: -24,
    },
    '@media (max-width: 768px)': {
      fontSize: '500%',
      right: -20,
      bottom: -50,
    },
    '@media (max-width: 448px)': {
      right: -10,
      bottom: -55,
    },
  },
  name: {
    fontSize: '1.5rem',
    color: '#272727',
    '@media (max-width: 448px)': {
      fontSize: '1.15rem',
    },
  },
  functions: {
    fontSize: '1rem',
    color: '#272727',
    '@media (max-width: 448px)': {
      fontSize: '0.875rem',
    },
  },
  btnLink: {},
  contentInfoPersonne: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  avatar: {
    borderRadius: '50%',
    width: 70,
    height: 70,
    marginRight: 16,
    overflow: 'hidden',
    border: '1px solid #80808099',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '@media (max-width: 448px)': {
      marginRight: 8,
      width: 50,
      height: 50,
    },
  },
  contentBtn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 94,
    '@media (max-width: 700px)': {
      marginTop: 24,
    },
  },
}));
