import { makeStyles } from '@material-ui/core';
import Bg from '../../../../images/element5.svg';

export default makeStyles((theme) => ({
  autresServicesWrapper: {
    display: 'flex',
    '@media (max-width: 1200px)': {
      flexWrap: 'wrap',
    },
    '@media (min-width: 980px)': {},
  },
  autresServiceContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  autresServicesTitreWrapper: {
    width: 542,
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(11),
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    '&:before': {
      width: '25.83%',
      height: '25%',
      backgroundColor: '#ffdb27d9',
      display: 'inline-block',
      content: '""',
      position: 'absolute',
      top: 148,
      left: 0,
      '@media (max-width: 1200px)': {
        top: 50,
      },
    },
    '@media (max-width: 1200px)': {
      paddingTop: theme.spacing(20),
      width: '100%',
      maxWidth: '100%',
      paddingBottom: theme.spacing(5),
      paddingLeft: 50,
      paddingRight: 50,
    },
    '@media (max-width: 992px)': {
      backgroundImage: 'inherit',
    },
    '@media (max-width: 768px)': {},
    '@media (max-width: 576px)': {},
  },
  autresServicesTitre: {
    color: '#fff',
    textTransform: 'initial',
    fontSize: '2.5rem',
  },
  autresServicebtnDemarrer: {
    color: '#fff',
    borderColor: '#fff',
    fontWeight: 400,
    maxWidth: 'fit-content',
    marginTop: theme.spacing(4),
    transition: '50ms ease-in',
    '@media (max-width: 1200px)': {
      marginTop: theme.spacing(4),
    },
    '@media (max-width: 360px)': {
      fontSize: '0.725rem',
      marginTop: 0,
    },
    '&:hover': {
      transition: '50ms ease-in',
      paddingLeft: 20,
      paddingRight: 20,
    },
    '& svg': {
      transition: '50ms ease-in',
    },
    '&:hover svg': {
      transition: '50ms ease-in',
      transform: 'translateX(5px)',
    },
  },
  autresServicesItemsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    '@media (max-width: 1200px)': {
      backgroundColor: '#FBFBFB',
    },
    '@media (max-width: 576px)': {
      flexWrap: 'wrap',
    },
  },
}));
